import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrRegistrationModuleEditPricingTierView',
  computed: {
    ...mapState('pricing', ['pricing_tiers'])
  }
})
export default class GtrRegistrationModuleEditPricingTierView extends GtrSuper {
  data () {
    return {
      loading: false,
      submitting: false,
      pricingTier: {
        name: null,
        datetime_start: null,
        datetime_end: null
      }
    }
  }

    @Watch('pricing_tiers')
  onPricingTiersChange (payload: any) {
    if (Array.isArray(payload)) {
      const tier = payload.filter((tier: any) => tier.uuid === this.$route.params.pricing_tier_uuid).pop()
      if (tier) {
        this.$data.pricingTier = {
          name: tier.name,
          datetime_start: this.convertDatetimeToLocalTimezone(tier.date_start),
          datetime_end: this.convertDatetimeToLocalTimezone(tier.date_end)
        }
      }
    }
  }

    async mounted () {
      const tiers = this.$store.state.pricing.pricing_tiers
      if (tiers.length > 0) {
        const tier = tiers.filter((tier: any) => tier.uuid === this.$route.params.pricing_tier_uuid).pop()
        if (tier) {
          this.$data.pricingTier = {
            name: tier.name,
            datetime_start: this.convertDatetimeToLocalTimezone(tier.date_start),
            datetime_end: this.convertDatetimeToLocalTimezone(tier.date_end)
          }
        }
      } else {
        await this.fetchPricingTiers()
      }
    }

    async submit () {
      const form = (this.$refs.editPricingTierForm as HTMLFormElement)
      if (form.validate()) {
        try {
          this.$data.submitting = true
          const payload: any = {
            event_uuid: this.$route.params.event_uuid,
            tier_uuid: this.$route.params.pricing_tier_uuid,
            data: {
              name: this.$data.pricingTier.name,
              date_start: this.convertDatetimeToUTC(this.$data.pricingTier.datetime_start),
              date_end: this.convertDatetimeToUTC(this.$data.pricingTier.datetime_end)
            }
          }
          await this.$store.dispatch('pricing/editPricingTier', payload)
          Container.get(Notification).success('Pricing tier successfully edited.')
          this.$router.push({
            name: 'level-two.modules.registration.pricing-tiers'
          })
        } catch (error) {
          Container.get(ErrorHandlerService).error(error)
        } finally {
          this.$data.submitting = false
        }
      }
    }

    private async fetchPricingTiers () {
      try {
        this.$data.loading = true
        await this.$store.dispatch('pricing/getPricingTiers', { event_uuid: this.$route.params.event_uuid })
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.loading = false
      }
    }
}
